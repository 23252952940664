














import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Task extends mixins(MenuMixin) {
  menu = [
    {
      title: '结算清分中心',
      icon: 'appstore',
      children: [{ title: '清分工作台', key: '/handle/review', icon: 'unordered-list' }]
    }
  ];

  created() {}
}
